import React from 'react';
import AccountCreation from './AccountCreation';

function VerifyAccountPageHeader() {
    return (
        <AccountCreation
            title="Verify Account"
            subtitle=""
            extlink=""
            ext=""
        />

    );
}

export default VerifyAccountPageHeader;