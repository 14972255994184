import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import HeaderDash from './HeaderDash';
import ProjectToolbar from './projectToolbar';
import ProjectTable from './ProjectsTable';
import Milestone from './Milestone';
import { Input, Modal, Flex, Spin, notification } from "antd";
import apiDetails from "../../assets/apiDetails/apiDetails.json";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const currentDate = new Date();
const dateFormat = 'YYYY-MM-DD';
// Minimum date set to today
const minDate = dayjs().format(dateFormat);

const ManageProjects = ({ user_details }) => {
  const location = useLocation();
  const isProjectsRoute = location.pathname === '/projects';
  const [view, setView] = useState(isProjectsRoute ? 'dashboard' : 'form');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDetailsPanelVisible, setDetailsPanelVisible] = useState(false);
  const [taskCompletion, setTaskCompletion] = useState({}); // State to track task completion
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listOfSkillsFromAPI, setListOfSKillsFromAPI] = useState([]);
  const [listOfSubProfessionalsFromAPI, setListOfSubProfessionalsFromAPI] = useState([]);
  const [listofaddedskills, setlistofaddedskills] = useState({});
  const [listOfBusinessUsersFromAPI, setListOfBusinessUsersFromAPI] = useState([]);
  const [listOfCreatedProjects, setListOfCreatedProjects] = useState([]);

  // const form details
  const [projectTitle, setSelectedProjectTitle] = useState("");
  const [projectDescription, setSelectedProjectDescription] = useState("");
  const [projectClientName, setSelectedProjectClientName] = useState("");
  const [selectedBusinessUser, setSelectedBusinessUser] = useState("");
  const [totalProjectCost, setTotalProjectCost] = useState("0");

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    // Only call fetchData once, ensuring no duplicate calls or errors.
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchAllSkills();
        await fetchAllSubProfessionalUsers();
        await fetchAllBusinessAccounts();
        await fetchAllCreatedProjects();
      } catch (error) {
        // openErrorNotification(error, "Failed to load meetings");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchAllSkills = async () => {
    const url_domain = apiDetails.domain;
    const version_path = apiDetails.version_path;
    const get_professiona_skills = "professional-skills";
    const fetch_url = `${url_domain}/${version_path}/${get_professiona_skills}`;
    const response = await fetch(fetch_url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_details.access_token}`
      }
    });

    // console.log("response from the get all assessment API call ");
    // console.log(response);

    if (!response.ok) {
      const errorData = await response.json();
      // openErrorNotification(errorData, "Failed to load meetings");
      // throw new Error(errorData);
      return
    }

    const data = await response.json();
    // console.log("response data from the get all meetings API call response.json() parsing function");
    // console.log(data);

    if (data && data.data && Array.isArray(data.data)) {
      setListOfSKillsFromAPI(data.data);
    }

  };

  const fetchAllSubProfessionalUsers = async () => {
    const url_domain = apiDetails.domain;
    const version_path = apiDetails.version_path;
    const get_all_sub_professionals = "get-all-sub-professionals";
    const fetch_url = `${url_domain}/${version_path}/${get_all_sub_professionals}`;
    const response = await fetch(fetch_url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_details.access_token}`
      }
    });

    // console.log("response from the get all assessment API call ");
    // console.log(response);

    if (!response.ok) {
      const errorData = await response.json();
      // openErrorNotification(errorData, "Failed to load meetings");
      // throw new Error(errorData);
      return
    }

    const data = await response.json();
    // console.log("response data from the get all meetings API call response.json() parsing function");
    // console.log(data);

    if (data && data.data && Array.isArray(data.data)) {
      setListOfSubProfessionalsFromAPI(data.data);
    }

  };

  const fetchAllBusinessAccounts = async () => {
    const url_domain = apiDetails.domain;
    const version_path = apiDetails.version_path;
    const get_all_business_accounts = "all-business-accounts";
    const fetch_url = `${url_domain}/${version_path}/${get_all_business_accounts}`;
    const response = await fetch(fetch_url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_details.access_token}`
      }
    });

    // console.log("response from the get all assessment API call ");
    // console.log(response);

    if (!response.ok) {
      const errorData = await response.json();
      // openErrorNotification(errorData, "Failed to load meetings");
      // throw new Error(errorData);
      return
    }

    const data = await response.json();
    // console.log("response data from the get all meetings API call response.json() parsing function");
    // console.log(data);

    if (data && data.data && Array.isArray(data.data)) {
      setListOfBusinessUsersFromAPI(data.data);
    }

  };

  const fetchAllCreatedProjects = async () => {
    const url_domain = apiDetails.domain;
    const version_path = apiDetails.version_path;
    const get_professiona_projects = "professional-projects";
    const fetch_url = `${url_domain}/${version_path}/${get_professiona_projects}`;
    const response = await fetch(fetch_url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_details.access_token}`
      }
    });

    // console.log("response from the get all assessment API call ");
    // console.log(response);

    if (!response.ok) {
      const errorData = await response.json();
      // openErrorNotification(errorData, "Failed to load meetings");
      // throw new Error(errorData);
      return
    }

    const data = await response.json();
    // console.log("response data from the get all meetings API call response.json() parsing function");
    // console.log(data);

    if (data && data.data && Array.isArray(data.data)) {
      console.log(data.data);
      setListOfCreatedProjects(data.data);
    }

  };

  // Keep the original projects array for the table
  const projects = [
    { client: 'Seedson Ltd', title: 'Market Research', dueDate: '12-Jun-2024', price: '2300 CAD', participants: '4 people', level: '50%' },
    { client: 'Brendy Ltd', title: 'Product Launch Plan', dueDate: '15-Jun-2024', price: '5000 CAD', participants: '3 people', level: '30%' },
    { client: 'Karsen Ltd', title: 'Financial Analysis', dueDate: '20-Jun-2024', price: '4000 CAD', participants: '5 people', level: '70%' },
  ];

  const skillslist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // New state to hold the detailed project information
  const [projectDetails, setProjectDetails] = useState({
    title: '',
    owner: '',
    description: '',
    skills: [],
    milestones: [],
    paymentType: '',
  });

  const handleTaskCompletion = (milestoneIndex, taskIndex) => {
    const taskKey = `${milestoneIndex}-${taskIndex}`;

    setTaskCompletion(prevState => ({
      ...prevState,
      [taskKey]: !prevState[taskKey], // Toggle the completion status
    }));
  };


  const handleCreateProject = (e) => {
    e.preventDefault();
    setProjectDetails({
      title: e.target.title.value,
      owner: e.target.owner.value,  // Capturing the Project Owner's email
      description: e.target.description.value,
      skills: e.target.skills.value.split(',').map(skill => skill.trim()),
      milestones: [
        {
          name: e.target.milestone.value,
          tasks: [e.target.task.value],
          budget: e.target.budget.value
        }
      ],
      paymentType: e.target.paymentType.value,
    });
    setView('dashboard');
  };

  const handleBackClick = () => {
    setView('form');
  };

  const handleCreateProjectClick = () => {
    setView('form');
  };

  const handleViewClick = (project) => {
    setSelectedProject(project);
    setDetailsPanelVisible(true);
  };

  const handleBackToDashboard = () => {
    setSelectedProject(null);
    setDetailsPanelVisible(false);
  };

  // Function to toggle task completion
  const toggleTaskCompletion = (milestoneIndex, taskIndex) => {
    setTaskCompletion(prevState => ({
      ...prevState,
      [`${milestoneIndex}-${taskIndex}`]: !prevState[`${milestoneIndex}-${taskIndex}`],
    }));
  };

  const addMilestone = () => {
    setMilestones([
      ...milestones,
      { name: '', user_id: "", cost: 0, tasks: [{ name: '', budget: '', due_date: minDate, unformattedDate: minDate }] },
    ]);
  };

  const handleMilestoneChange = (e, milestoneIndex) => {
    const newMilestones = [...milestones];
    newMilestones[milestoneIndex].name = e.target.value;
    setMilestones(newMilestones);
  };

  const addTask = (milestoneIndex) => {
    const newMilestones = [...milestones];
    newMilestones[milestoneIndex].tasks.push({ name: '', budget: '', due_date: minDate, unformattedDate: minDate });
    setMilestones(newMilestones);
  };

  const calculateMilestoneTotalCost = (milestoneIndex) => {
    const newMilestones = [...milestones];
    const milestonetasks = newMilestones[milestoneIndex].tasks;
    const totalCost = milestonetasks.reduce((sum, task) => {
      return sum + Number(task.budget);
    }, 0);

    newMilestones[milestoneIndex].cost = totalCost;
    setMilestones(newMilestones);
  };

  const calculateTotalProjectCost = () => {
    const newMilestones = [...milestones];
    const totalCost = newMilestones
      .flatMap(obj => obj.tasks)  // merge all tasks into one array
      .reduce((sum, task) => sum + Number(task.budget), 0);

    setTotalProjectCost(totalCost);
  };

  const handleTaskChange = (e, milestoneIndex, taskIndex, field) => {
    const newMilestones = [...milestones];
    newMilestones[milestoneIndex].tasks[taskIndex][field] = e.target.value;
    setMilestones(newMilestones);

    if (field === "budget") {
      calculateMilestoneTotalCost(milestoneIndex);
      calculateTotalProjectCost();
    }
  };

  const onChangeDueDate = (date, milestoneIndex, taskIndex, unformattedDate) => {
    // console.log(date, milestoneIndex, taskIndex);
    // setMeetingFormDate(date);
    const newMilestones = [...milestones];
    newMilestones[milestoneIndex].tasks[taskIndex]["due_date"] = date;
    newMilestones[milestoneIndex].tasks[taskIndex]["unformattedDate"] = unformattedDate;
    setMilestones(newMilestones);
  };

  const addSkill = (key, value) => {
    setlistofaddedskills((prevSkills) => {
      if (prevSkills[key]) {
        return prevSkills; // Key already exists, return the current state
      }
      return { ...prevSkills, [key]: value }; // Add new key-value pair
    });
  };

  const deleteSkill = (key) => {
    setlistofaddedskills((prevSkills) => {
      const newSkills = { ...prevSkills }; // Create a shallow copy
      delete newSkills[key]; // Remove the key
      return newSkills; // Update the state
    });
  };

  const handleSkillsDropdownChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const slug = selectedOption.getAttribute('data-slug');
    const id = selectedOption.getAttribute('data-id');
    const position = selectedOption.getAttribute('data-position');
    const name = selectedOption.text;
    const data_name = selectedOption.getAttribute('data-name');

    // You can use these details as needed
    // console.log('Selected Skill Details:', { name, slug, id, position });

    if (listofaddedskills[id]) {
      return;
    } else {
      addSkill(id, data_name);
    }
  };

  const handleBusinessUserSelectionChange = (event) => {
    // const selectedOption = event.target.options[event.target.selectedIndex];
    // const id = selectedOption.getAttribute('data-id');
    // setSelectedBusinessUser(id);
    const value = event.target.value;
    setSelectedBusinessUser(value);
  }

  const updateSubProfessionalForMilestone = (selectedSubProfessional, milestoneIndex) => {
    const newMilestones = [...milestones];
    newMilestones[milestoneIndex].user_id = selectedSubProfessional;
    setMilestones(newMilestones);
    // console.log(newMilestones);
  };

  const handleProjectTitle = (event) => {
    const value = event.target.value;
    setSelectedProjectTitle(value);
  };

  const handleProjectDescription = (event) => {
    const value = event.target.value;
    setSelectedProjectDescription(value);
  };

  const handleClientName = (event) => {
    const value = event.target.value;
    setSelectedProjectClientName(value);
  };

  const parseTasksDueDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // Convert the found Date back to the original format if needed
  const formatDate = (dateObj) => {
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getProjectEndDate = (milestoneslist) => {
    // Flatten out all tasks due dates
    const allDueDates = milestoneslist.flatMap(item => item.tasks.map(task => task.due_date));

    // Find the maximum due date
    let latestDate = null;

    for (const dueDateStr of allDueDates) {
      const dateObj = parseTasksDueDate(dueDateStr);
      if (!latestDate || dateObj > latestDate) {
        latestDate = dateObj;
      }
    }

    return formatDate(latestDate);
  };

  const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
    // console.loglog("notificationParameters = ", notificationParameters);
    api["error"]({
      message: errorTitle,
      description: notificationParameters.message,
      showProgress: true,
      pauseOnHover: true,
      duration: 0,
    });
  };

  const createProjectButton = (e) => {
    e.preventDefault();
    let completeAPIBody = {};
    completeAPIBody.name = projectTitle;
    completeAPIBody.description = projectDescription;
    completeAPIBody.client = projectClientName;
    completeAPIBody.skills = Object.keys(listofaddedskills);
    completeAPIBody.business_user_id = selectedBusinessUser;
    completeAPIBody.end_date = ((milestones.length > 0) && getProjectEndDate(milestones));
    completeAPIBody.milestones = milestones;
    // console.log("completeAPIBody = ", completeAPIBody);

    // need to implement the error handling here

    async function executeApiCalls() {
      await Promise.all([
        createProjectAPICall(completeAPIBody)
      ]);
      setLoading(false);

    }
    setLoading(true);
    executeApiCalls();
  };

  const createProjectAPICall = async (apiJSONBody) => {
    try {
      const url_domain = apiDetails.domain;
      const version_path = apiDetails.version_path;
      const create_professional_project = "professional-project/create";
      const fetch_url = `${url_domain}/${version_path}/${create_professional_project}`;

      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_details.access_token}`
        },
        body: JSON.stringify(apiJSONBody)
      });

      if (!response.ok) {
        const error_data = await response.json();
        // console.log("error_data", error_data);
        openErrorNotification(error_data);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("response from the send message between user and professional API call response.json() parsing function");
      // console.log(data);
      if (data && data.errors) {
        openErrorNotification(data);
        throw new Error(`Error`);
      }
      else if (data && data.error) {
        openErrorNotification(data);
        throw new Error(`Error`);
      }
      else {
        setView('dashboard');
      }


    } catch (error) {
      // setUserRecentChats(userRecentChatListTestingData);
      // console.error('Error fetching data from API 3 - get all remediations API call', error);
    }
  }


  return (
    <>
      {contextHolder}
      {loading ? (
        <Flex align="center" gap="middle">
          <Spin size="large" className="spinner-container" />
        </Flex>
      ) : view === 'form' ? (
        <div className="manage-projects-page">
          <div className="header-create">
            <h1>Create projects</h1>
            <p>Create projects and assign them to your team of professionals.</p>
          </div>
          {/* onSubmit={handleCreateProject} */}
          <form className="manage-projects-form">
            <div className="form-group">
              <label>Project title?</label>
              <input type="text" name="title" value={projectTitle} placeholder="Enter project title" onChange={(e) => handleProjectTitle(e)} />
            </div>

            {/* <div className="form-group">
              <label>Project Owner's email</label>
              <input type="email" name="owner" placeholder="Enter project Owner's email" />
            </div> */}

            <div className="form-group">
              <label>Project description</label>
              <textarea name="description" placeholder="Enter project description" value={projectDescription} onChange={(e) => handleProjectDescription(e)}></textarea>
            </div>
            <div className="form-group">
              <label>Client Name</label>
              <input type="text" name="clientName" placeholder="Enter Client Name" value={projectClientName} onChange={(e) => handleClientName(e)} />
            </div>

            <div className="form-group">
              <label>Business User</label>
              {/* <select name="business-user" id="business-user" defaultValue="" value={selectedBusinessUser} onChange={handleBusinessUserSelectionChange}>
                <option value="" disabled>
                  Select Business User
                </option>
                {listOfBusinessUsersFromAPI.map((business_user, index) => (
                  <option
                    key={business_user.id}
                    value={business_user.id}
                    data-id={business_user.id}
                    data-position={index}
                    data-name={business_user.name}
                  >
                    {business_user.name}
                  </option>
                ))}
              </select> */}
              <input type="email" name="businessuserowner" placeholder="Enter business user's email" value={selectedBusinessUser} onChange={(e) => handleBusinessUserSelectionChange(e)} />
            </div>

            <div className="form-group">
              <label>Skills needed</label>
              <select name="skills-needed" id="skills-select" defaultValue="" onChange={handleSkillsDropdownChange}>
                <option value="" disabled>
                  Select Required Skills
                </option>
                {listOfSkillsFromAPI.map((skill, index) => (
                  <option
                    key={skill.id}
                    value={skill.id}
                    data-slug={skill.slug}
                    data-id={skill.id}
                    data-position={index}
                    data-name={skill.name}
                  >
                    {skill.name}
                  </option>
                ))}
              </select>

              <div className="list-of-skills" style={{ marginTop: '1rem' }}>
                <div className="list-of-skills-wrapper">
                  {/* {listofaddedskills.map((skill, skillIndex) => (
                    <div key={skillIndex} className="skillName">
                      <span>Skill {skill}</span>
                      <button className="added-skillName-tag-close-btn">&times;</button>
                    </div>
                  ))} */}
                  {Object.entries(listofaddedskills).map(([key, value], skillIndex) => (
                    <div key={key} className="skillName">
                      <span>{value}</span>
                      <button
                        className="added-skillName-tag-close-btn"
                        onClick={() => deleteSkill(key)} // Add an onClick handler to remove the skill
                      >
                        &times;
                      </button>
                    </div>
                  ))}

                </div>
              </div>
            </div>

            {milestones.map((milestone, milestoneIndex) => (
              <Milestone
                key={milestoneIndex}
                milestoneIndex={milestoneIndex}
                milestone={milestone}
                handleMilestoneChange={handleMilestoneChange}
                addTask={addTask}
                handleTaskChange={handleTaskChange}
                listOfSubProfessionalsFromAPI={listOfSubProfessionalsFromAPI}
                updateSubProfessionalForMilestone={updateSubProfessionalForMilestone}
                onChangeDueDate={onChangeDueDate}
              />
            ))}

            <div className="milestonebutton-row">
              <button type="button" className="add-task-button" onClick={addMilestone}>
                Add new milestone
              </button>
            </div>
            {/* <div className="form-group">
              <label>Payment type</label>
              <select name="paymentType">
                <option>Offline</option>
                <option>Online</option>
              </select>
            </div> */}
            <div className="form-group">
              <label>Project Cost in Total</label>
              <input type="text" name="projectCost" disabled value={totalProjectCost} />
            </div>
            <button className="create-project-button" onClick={createProjectButton}>
              Create project
            </button>
          </form>
        </div>
      ) : (
        <>
          <ProjectToolbar onBackClick={handleBackClick} onCreateProjectClick={handleCreateProjectClick} />
          <div className="totalassess-container">
            <div className="assess-container">
              <ProjectTable projects={listOfCreatedProjects} onViewClick={handleViewClick} />
            </div>
          </div>

          {/* Updated Project Details Panel */}
          <div className={`details-panel ${isDetailsPanelVisible ? 'visible' : ''}`}>
            <div className="details-header">
              <img src="/images/image.png" alt="Project Icon" className="project-details-image" />
              <h2>Project Details</h2>
              <button className="back-button" onClick={handleBackToDashboard}>
                Back
              </button>
            </div>

            {selectedProject && (
              <div className="details-content">
                <div className="project-details-header">
                  <div className="project-title-section">
                    <h3 className="project-title-label">Project title</h3>
                    <p className={`project-title-input ${selectedProject.title ? 'no-border' : ''}`}>
                      {selectedProject.title}
                    </p>
                  </div>

                  <div className="project-owner-section">
                    <h3>Project Owner</h3>
                    <p>{projectDetails.owner}</p>
                  </div>
                </div>

                <h3>Project description</h3>
                <p>{projectDetails.description}</p>

                <h3>Skills needed</h3>
                <div className="skills-container">
                  {projectDetails.skills.map((skill, index) => (
                    <div key={index} className="skill-rectangle-container">
                      <span className="skill-rectangle-text">{skill}</span>
                    </div>
                  ))}
                </div>

                <h3>Milestones</h3>
                {projectDetails.milestones.map((milestone, index) => (
                  <div key={index} className="milestone-container">
                    <h4>{milestone.name}</h4>
                    <ul className="task-list">
                      {milestone.tasks.map((task, taskIndex) => (
                        <li key={taskIndex} className="task-item">
                          <input
                            type="checkbox"
                            checked={taskCompletion[`${index}-${taskIndex}`] || false}
                            onChange={() => handleTaskCompletion(index, taskIndex)}
                          />
                          <span
                            className={`task-text ${taskCompletion[`${index}-${taskIndex}`] ? 'completed-task' : ''
                              }`}
                          >
                            {task}
                          </span>
                        </li>
                      ))}
                    </ul>
                    {milestone.budget && <p className="budget-text">Budget: {milestone.budget}</p>}
                  </div>
                ))}

                <div className="form-group">
                  <h3>Add attachments</h3>
                  <div
                    className="attachment-dropzone"
                    onClick={() => document.getElementById('file-upload').click()}
                  >
                    <span className="drop-text">Drop documents +</span>
                    <input
                      type="file"
                      id="file-upload"
                      name="attachments"
                      multiple
                      className="drop-documents"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );

};

export default ManageProjects;
