import React, { useState, useEffect } from "react";
import { Flex, Spin, notification } from 'antd';
import { getInitials } from "./GetInitialsFunction";
import apiDetails from "../../assets/apiDetails/apiDetails.json";

const UserRecentChatListComponenet = ({ onChatClick, user_details }) => {
    const [activeIndex, setActiveIndex] = useState(-1); // Initially, the first item is active
    const [loading, setLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();
    const [user_recent_chats, setUserRecentChats] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await Promise.all([
                fetchAllUserRecentChats()
            ]);
            setLoading(false);
        }
        fetchData();
    }, []);

    const fetchAllUserRecentChats = async () => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const get_conversations = "get-conversations";
            const fetch_url = `${url_domain}/${version_path}/${get_conversations}`;

            const response = await fetch(fetch_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                }
            });

            // console.log("response from the get all recent conversations API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the get all recent conversations API call response.json() parsing function");
            // console.log(data);

            if (data && data.data) {
                setUserRecentChats(data.data);
            }

            // setUserRecentChats(data.data);
            // filterProjectsByStatus(data)
        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const openErrorNotification = (notificationParameters) => {
        // // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to retrieve recent chats',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const handleChatClick = (index, reciever_professional_id) => {
        setActiveIndex(index); // Update the active index when a chat item is clicked
        // console.log("chat index clicked is == ", reciever_professional_id);
        onChatClick(reciever_professional_id);  // Calling the callback with the chatId
    };

    const ChatItemComponent = ({ chat, isActive, onClick }) => {
        const chatItemClass = isActive ? "chat-item active" : "chat-item";

        return (
            <div key={chat.id} className={chatItemClass} onClick={onClick}>
                <div className="avatar avatarForOutIncomingMessage">{(chat.sender.id !== user_details.logged_in_user_id) ? getInitials(chat.sender.name) : getInitials(chat.receiver.name)}</div>
                <div className="chat-info">
                    <div className="chat-name">{(chat.sender.id !== user_details.logged_in_user_id) ? chat.sender.name : chat.receiver.name}</div>
                    <div className="chat-preview">{chat?.message?.substring(0, 40) || ""}...</div>
                </div>
                <div className='last-seen-details'>
                    {/* {chat && chat.is_read && <div className="unread-days"></div>} */}
                    {chat && (chat.sender.id !== user_details.data.id) && (!chat.is_read) && <div className="unread-count"></div>}
                </div>
            </div>
        );
    }

    return (
        <div className="chat-list">
            {contextHolder}
            {/* Chat list items */}
            {loading === true && (
                <Flex align="center" gap="middle" className="loaderSpinner">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            )}
            {loading === false && user_recent_chats.length === 0 && (
                <div className="noRecentChatsAvailable">No Recent Chats</div>
            )}
            {loading === false && user_recent_chats.length !== 0 && user_recent_chats.map((chat, index) => (
                <ChatItemComponent
                    key={index}
                    chat={chat}
                    isActive={index === activeIndex}
                    onClick={() => handleChatClick(index, ((chat.sender.id !== user_details.logged_in_user_id) ? chat.sender.id : chat.receiver.id))}
                />
            ))}
        </div>
    );

};

export default UserRecentChatListComponenet;