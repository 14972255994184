import React, { useState } from "react";
import SignupForm from "./Forms";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Spin, notification } from "antd";

function VerifyAccountForm({ onLogin }) {
    // const location = useLocation();
    const navigate = useNavigate();
    // const email = location.state?.email || ""; // Retrieve the email from state
    const [api, contextHolder] = notification.useNotification();
    const [isLoading, setIsLoading] = useState(false);

    function handleLoginFinish(values) {
        console.log("hanish*** ", values);
        const userotp = values.otp;
        console.log("userotp*** ", userotp);
        // Include the email in the data sent to the API
        apiCallToVerifyAccountUser(userotp);
    }

    const openErrorNotification = (notificationParameters) => {
        // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to verify account',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const apiCallToVerifyAccountUser = async (userotp) => {
        // console.log("hani *** ", data);
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/verify-account', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "otp": userotp
                }),
            });

            if (!response.ok) {
                const error_data = await response.json();
                openErrorNotification(error_data);
                throw new Error('Failed to verify account');
            }

            const result = await response.json();
            console.log('Success:', result);
            // Handle the success case, e.g., show a success message or redirect

            if (result && result.error) {
                openErrorNotification(result);
                throw new Error('Failed to verify account');
            }
            // navigate('/login');
            const userName = result.data.name;
            onLogin(userName, result);
            navigate('/Dashboard', { state: { result } });
        } catch (error) {
            console.error('Error:', error);
            // Handle the error case, e.g., show an error message
        }
    };


    const formItems = [
        { label: "Enter OTP from your mail?", name: "otp", rules: [{ required: true, message: 'Please enter your OTP' }, { pattern: /^[A-Za-z0-9]{6}$/, message: 'Please enter valid' }] }
    ];


    return (
        <>
            {contextHolder}
            {isLoading === true && (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            )}
            <SignupForm
                formItems={formItems}
                onFinish={handleLoginFinish}
                buttonText="Verify Account"
            />
        </>

    );
};

export default VerifyAccountForm;
