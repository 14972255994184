import React, { useState } from "react";

const InviteProfessionals = ({ onSendInvitation, name }) => {
  const [emails, setEmails] = useState([""]);

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleSendInvitation = () => {
    console.log("Emails to send invitations to:", emails);
    onSendInvitation(emails); 
  };

  return (
    <>
      <div className="send-assessment-page">
        <div className="sendheader">
          <img src="/Images/svgviewer-png-output.png" alt="Company Logo" />
          <h1 className="sendtitle">Invite Professionals</h1>
          <p className="sendsubtitle">Send an invite to prospective professionals to take an assessment.</p>
        </div>
        <div className="email-input-container">
          {emails.map((email, index) => (
            <div key={index} className="email-input-wrapper">
              <label>What is the recipient's email?</label>
              <input
                type="email"
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                placeholder="Enter email"
              />
            </div>
          ))}
          <button onClick={handleAddEmail} className="add-email-button">
            Add another email +
          </button>
        </div>
        <button onClick={handleSendInvitation} className="send-invitation-button">
          Send Invitation
        </button>
      </div>
    </>
  );
};

export default InviteProfessionals;
