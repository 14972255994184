import './App.css';
import React, { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { Alert } from 'antd';
import Header from "./Components/signupComp/Header";
import HeaderDash from "./Components/Dashboard/HeaderDash";
import Progressstep from './Components/signupComp/Progressstep';
import SignupForm from './Components/signupComp/Forms';
import Signup from './Components/signupComp/Signup';
import Login from './Components/signupComp/login';
import LoginForm from './Components/signupComp/Loginform';
import Forgot from './Components/signupComp/forgot';
import ForgetForm from './Components/signupComp/forgotForm';
import EmailForgot from './Components/signupComp/forgotEmailForm';
import Dashboard from './Components/DashboardAsssemble';
// import BookMeetings from './Components/Dashboard/bookmeetingbutton';
import BusinessList from './Components/Dashboard/BusinessList';
import ManageProjects from './Components/Dashboard/manageproject';
import Workforce from './Components/Dashboard/workforce';
import RecommendedProfessionalProfilePage from './Components/Dashboard/RecommendedProfessionalProfilePage';
import MeetingsPageContainer from './Components/Dashboard/meetings/MeetingsPageContainer';
import RegisterSubProfessionalComponent from './Components/signupComp/RegisterSubProfessionalComponent';
import { Flex, Spin, notification } from "antd";
import apiDetails from "./assets/apiDetails/apiDetails.json";
import UserChatInterface from "./Components/MessagingPortal/UserChatInterface";
import VerifyAccountPageHeader from './Components/signupComp/VerifyAccountPageHeader';
import VerifyAccountForm from "./Components/signupComp/VerifyAccountForm";

// https://professional.anvillist.com/sub-professional/register?email=xx@emaal.com&id=84838h8ew8we

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [inputValues, setInputValues] = useState({});
    const [industryValue, setIndustryValue] = useState("");
    const [user_details, setUserDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const location = useLocation();


    const isDashboardRoute = /^\/(dashboard|meetings|assessment|projects|workforce|recommended-profile|inbox)/i.test(location.pathname);


    const shouldShowDashboardHeader = isLoggedIn && isDashboardRoute; // Show `HeaderDash` only for authenticated users on dashboard routes
    const shouldShowDefaultHeader = !shouldShowDashboardHeader; // Show `Header` in all other cases

    // state management for sub professional registration
    const [currentStepForSubProfessionalRegistration, setCurrentStepForSubProfessionalRegistration] = useState(0);
    const [emailForSubProfessionalRegistration, setEmailForSubProfessionalRegistration] = useState("");
    const [idForSubProfessionalRegistration, setIDForSubProfessionalRegistration] = useState("");


    const itemsToShowForSubProfessionalRegister = [
        { title: 'Provide basic info' },
        { title: 'Create your password' }
    ];

    const [formItemsForSubProfessionalRegistration, setFormStateForSubProfessionalRegistration] = useState([
        { label: "What is your email?", name: "email", rules: [{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email address' }] },
        { label: "What is your first name?", name: "firstName", rules: [{ required: true, message: 'Please enter your first name' }] },
        { label: "What is your last name?", name: "lastName", rules: [{ required: true, message: 'Please enter your last name' }] },
        { label: "What is your mobile number?", name: "phone", rules: [{ required: true, message: 'Please enter your Mobile Number' }, { pattern: /^[0-9]{10}$/, message: 'Please enter 10 digits' }] },
        {
            label: "Create your password",
            value: 'pwdcheck',
            name: "password",
            rules: [
                { required: true, message: 'Please create your password' },
                { min: 8, message: 'Password must have following criteria' },
            ]
        },
        {
            label: "Confirm password", name: "confirmPassword", rules: [
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                    }
                })
            ]
        }
    ]);

    const formSliceForSubProfessionalRegistration = () => {
        let itemsToShowForSubProfessionalRegistration = [];
        let buttonTextForSubProfessionalRegistration = 'Next';
        if (currentStepForSubProfessionalRegistration === 0) {
            itemsToShowForSubProfessionalRegistration = formItemsForSubProfessionalRegistration.slice(0, 4);
        } else if (currentStepForSubProfessionalRegistration === 1) {
            itemsToShowForSubProfessionalRegistration = formItemsForSubProfessionalRegistration.slice(4, 6);
        }
        return { itemsToShowForSubProfessionalRegistration, buttonTextForSubProfessionalRegistration };
    };

    const { itemsToShowForSubProfessionalRegistration, buttonTextForSubProfessionalRegistration } = formSliceForSubProfessionalRegistration();

    const setEmailAndIDForSubProfessionalRegistration = (email = "", id = "") => {
        setEmailForSubProfessionalRegistration(email);
        setIDForSubProfessionalRegistration(id);
        setFormStateForSubProfessionalRegistration(prevState =>
            prevState.map(item =>
                item.name === "email" // Target the "email" object
                    ? { ...item, value: email } // Update the value
                    : item // Keep other items unchanged
            )
        );
    };

    const handleFormFinishForSubProfessionalRegistration = (values) => {
        if (currentStepForSubProfessionalRegistration < 1) {
            setCurrentStepForSubProfessionalRegistration(currentStepForSubProfessionalRegistration + 1);
        }
        setInputValues((prevValues) => {
            const firstName = values.firstName || prevValues.firstName || "";
            const lastName = values.lastName || prevValues.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim();
            const allValues = {
                ...prevValues,
                ...values,
                name: fullName,
            };
            if (currentStepForSubProfessionalRegistration === 1) {
                // postSignupData(allValues);
                console.log("allValues = ", allValues);
                async function executeApiCalls() {
                    await Promise.all([
                        resetPasswordForSubProfessionalFromInvitationLink(allValues)
                    ]);
                    setLoading(false);
                }
                setLoading(true);
                executeApiCalls();
            }
            return allValues;
        });
    };

    const resetPasswordForSubProfessionalFromInvitationLink = async (formDataFromSubProfessionalResetPassword) => {
        try {
            const apiJSONBody = {
                "password": formDataFromSubProfessionalResetPassword.password,
                "name": formDataFromSubProfessionalResetPassword.firstName,
                "phone": formDataFromSubProfessionalResetPassword.phone,
            };

            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const sub_professional = "sub-professional";
            const create_account = "create-account";
            const fetch_url = `${url_domain}/${version_path}/${sub_professional}/${idForSubProfessionalRegistration}/${create_account}`;

            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                const default_message = {
                    "message": "Failed to Reset Password."
                }
                openErrorNotification(default_message);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("response from the send message between user and professional API call response.json() parsing function");
            console.log(data);

            if (data) {
                setLoading(false);
                navigate('/login');
            }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: errorTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const handleLogin = (name, user_details) => {
        setIsLoggedIn(true);
        setUserName(name);
        setUserDetails(user_details);
        // console.log("user details = ", user_details);
    };

    const handleFormFinish = (values) => {
        if (currentStep < formItems.length - 1) {
            setCurrentStep(currentStep + 1);
        }
        setInputValues((prevValues) => {
            const firstName = values.firstName || prevValues.firstName || "";
            const lastName = values.lastName || prevValues.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim();
            const allValues = {
                ...prevValues,
                ...values,
                name: fullName,
                type: "Professional",
                industry: industryValue,
            };
            if (currentStep === 2) {
                postSignupData(allValues);
            }
            return allValues;
        });
    };

    const postSignupData = async (data) => {
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/register', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                if (response.status === 422) {
                    alert("The email has already been taken.");
                }
                throw new Error('Network response was not ok');
            }
            // const result = await response.json();
            window.location.href = '/verify-account';
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChildData = (data) => {
        setIndustryValue(data);
    };

    const formItems = [
        { label: "What is your email?", name: "email", emailFor: "sub-professional", rules: [{ required: true, message: 'Please enter your email' }] },
        { label: "What is your first name?", name: "firstName", rules: [{ required: true, message: 'Please enter your first name' }] },
        { label: "What is your last name?", name: "lastName", rules: [{ required: true, message: 'Please enter your last name' }] },
        { label: "What is your mobile number?", name: "phone", rules: [{ required: true, message: 'Please enter your Mobile Number' }, { pattern: /^[0-9]{10}$/, message: 'Please enter 10 digits' }] },
        { label: "What is your business name?", name: "business_name", rules: [{ required: true, message: 'Please enter your business name' }] },
        { label: "What industry does your business belong to?", name: "industry", rules: [{ required: false, message: 'Please enter your business industry' }] },
        {
            label: "Create your password",
            value: 'pwdcheck',
            name: "password",
            rules: [
                { required: true, message: 'Please create your password' },
                { min: 8, message: 'Password must have following criteria' },
            ]
        },
        {
            label: "Confirm password", name: "confirmPassword", rules: [
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                    }
                })
            ]
        },
    ];

    const { itemsToShow, buttonText } = formslice();

    function formslice() {
        let itemsToShow = [];
        let buttonText = 'Next';
        if (currentStep === 0) {
            itemsToShow = [formItems[0]];
        } else if (currentStep === 1) {
            itemsToShow = formItems.slice(1, 6);
        } else {
            itemsToShow = formItems.slice(6, 8);
            buttonText = 'Create My Account';
        }
        return { itemsToShow, buttonText };
    }

    const items = [
        { title: 'Enter your email address' },
        { title: 'Provide basic info' },
        { title: 'Create your password' },
    ];

    const resetPassword = async (data) => {
        console.log("hani *** ", data);
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/password/reset', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Success:', result);
            // Handle the success case, e.g., show a success message or redirect
            // navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            // Handle the error case, e.g., show an error message
        }
    };

    return (
        <>
            {contextHolder}
            {shouldShowDefaultHeader && <Header />}
            {shouldShowDashboardHeader && <HeaderDash name={userName} />}
            {(loading) ? (
                <Flex align="center" gap="middle" className="loaderSpinner">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            ) : (
                <Routes>
                    <Route
                        path="/"
                        element={<><Login /><LoginForm onLogin={handleLogin} /></>}
                    />
                    <Route
                        path="/login"
                        element={<><Login /><LoginForm onLogin={handleLogin} /></>}
                    />
                    <Route
                        path="/signup"
                        element={<>
                            <Signup />
                            <Progressstep current={currentStep} items={items} />
                            <SignupForm formItems={itemsToShow} sendDataToParent={handleChildData} onFinish={handleFormFinish} buttonText={buttonText} />
                        </>
                        }
                    />
                    <Route
                        path="/forgot-password"
                        element={<><Forgot /><EmailForgot /></>}
                    />
                    <Route
                        path="/otp"
                        element={<><Forgot /><ForgetForm /></>}
                    />
                    <Route
                        path="/verify-account"
                        element={<><VerifyAccountPageHeader /><VerifyAccountForm onLogin={handleLogin} /></>}
                    />
                    <Route
                        path="/dashboard"
                        element={<Dashboard userName={userName} user_details={user_details} />}
                    />
                    {/* <Route path="/meetings" element={<BookMeetings />} /> */}
                    <Route path="/meetings" element={<MeetingsPageContainer user_details={user_details} />} />
                    <Route path="/assessment" element={<BusinessList startingTab="completed" />} />
                    <Route path="/projects" element={<ManageProjects user_details={user_details} />} />
                    <Route path="/workforce" element={<Workforce user_details={user_details} />} />
                    <Route path="/recommended-profile" element={<RecommendedProfessionalProfilePage />} />
                    <Route
                        path="sub-professional/register"
                        element={
                            <>
                                <Signup />
                                <Progressstep current={currentStepForSubProfessionalRegistration} items={itemsToShowForSubProfessionalRegister} />
                                <RegisterSubProfessionalComponent
                                    formItems={itemsToShowForSubProfessionalRegistration}
                                    buttonText={buttonTextForSubProfessionalRegistration}
                                    onFinish={handleFormFinishForSubProfessionalRegistration}
                                    loadSearchParams={setEmailAndIDForSubProfessionalRegistration}
                                    emailForSubProfessionalRegistrationProp={emailForSubProfessionalRegistration}
                                />

                            </>

                        }
                    />
                    <Route path="/inbox" element={<UserChatInterface user_details={user_details} />} />
                </Routes>
            )}


        </>
    );
}

export default App;
