import React, { useState } from 'react';
import AssessmentTable from './AssessmentTable';
import NewProfessional from './NewProfessional';
// import { useNavigate } from 'react-router-dom'; 
// Import useNavigate instead of useHistory
import apiDetails from "../../assets/apiDetails/apiDetails.json";
import { Flex, Spin, notification } from "antd";

const Workforce = ({ user_details }) => {
    const [currentView, setCurrentView] = useState('workforce');
    const [selectedProfessional, setSelectedProfessional] = useState(null); // State to track selected professional
    // const navigate = useNavigate(); 
    // Use useNavigate for navigation
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const assessments = [
        {
            date: '12-Jun-2024',
            company: 'Andrea Maison',
            country: 'Canada',
            industry: 'Digital Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Brendy Ltd',
            country: 'Canada',
            industry: 'Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Meech Merch Ltd',
            country: 'Canada',
            industry: 'Product Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Seedson Ltd',
            country: 'Canada',
            industry: 'Digital Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Brendy Ltd',
            country: 'Canada',
            industry: 'Growth',
        },
        {
            date: '12-Jun-2024',
            company: 'Meech Merch Ltd',
            country: 'Canada',
            industry: 'Sales',
        },
    ];

    const handleBackClick = () => {
        window.history.back();
    };

    const onNewProfessional = (professional) => {
        setSelectedProfessional(professional);
        setCurrentView('newProfessional');
    };

    const goBackToWorkforce = () => {
        setCurrentView('workforce');
        setSelectedProfessional(null);
    };

    // const handleProfileRedirect = () => {
    //     navigate('/recommended-profile'); // Use navigate to redirect
    // };

    const handleSendInviteToSubProfessionalsButtonClick = (listOfSubProfessionalEmails) => {
        async function executeApiCalls() {
            await Promise.all([
                inviteSubProfessionals(listOfSubProfessionalEmails)
            ]);
            setLoading(false);

        }
        setLoading(true);
        executeApiCalls();
    };

    const inviteSubProfessionals = async (listOfSubProfessionalEmails) => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const professional = "professional";
            const invite_sub_professional = "invite-sub-professional";
            const fetch_url = `${url_domain}/${version_path}/${professional}/${user_details?.data?.id || ""}/${invite_sub_professional}`;
            const apiBody = {
                "emails": listOfSubProfessionalEmails
            };

            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                },
                body: JSON.stringify(apiBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the inviteSubProfessionals API call response.json() parsing function");
            // console.log(data);

            if (data) {
                openSuccessNotification(data);
                goBackToWorkforce();
            }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: errorTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const openSuccessNotification = (notificationParameters, successTitle = "Success") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["success"]({
            message: successTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 30,
        });
    };

    if (currentView === 'newProfessional' && selectedProfessional) {
        return (
            <div style={{ marginTop: '50px', padding: '0 20px' }}>
                <NewProfessional professional={selectedProfessional} onBack={goBackToWorkforce} onSendInvitation={handleSendInviteToSubProfessionalsButtonClick} />
            </div>
        );
    }

    return (
        <div>
            {contextHolder}
            {(loading) ? (
                <Flex align="center" gap="middle" className="loaderSpinner">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            ) : (
                <div style={{ marginTop: '50px', padding: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                            <button className="back-button" onClick={handleBackClick}>
                                &larr; <span>Back</span>
                            </button>
                            <div className="search-container">
                                <input
                                    type="text"
                                    placeholder="Search by profession or name"
                                    className="search-inputproject"
                                />
                            </div>
                        </div>

                        {/* View Button for Andrea Maison */}
                        <button
                            className="view-btn"
                            onClick={() => onNewProfessional({})} // Changed this line
                            style={{ bottom: '15px', padding: '12px 18px', marginRight: '40px' }}
                        >
                            New Professional
                        </button>
                    </div>
                    <div style={{ width: '1195px', height: '651px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', opacity: '1', margin: '0 auto' }}>
                        <AssessmentTable assessments={assessments} />
                    </div>
                </div>
            )}
        </div>

    );
};

export default Workforce;

