import React, { useState, useEffect } from 'react';
import Card from './Dashboard/cardDash';
import AssessmentTable from './Dashboard/AssessmentTable';
import MeetingsTable from './Dashboard/MeetingsTable';
import ProjectsTable from './Dashboard/ProjectsTable';
import TabNavigation from './Dashboard/TabNav';
import InviteNavigation from './Dashboard/InviteNavigation';
import BookMeetings from './Dashboard/bookmeetingbutton';
import ManageProjects from './Dashboard/manageproject';
import apiDetails from "../assets/apiDetails/apiDetails.json";

const Dashboard = ({ userName, user_details }) => {
    const [currentView, setCurrentView] = useState('dashboard');
    const [listOfCreatedProjects, setListOfCreatedProjects] = useState([]);
    const handleCardClick = (view) => setCurrentView(view);

    useEffect(() => {
        // Only call fetchData once, ensuring no duplicate calls or errors.
        const fetchData = async () => {
          try {
            await fetchAllCreatedProjects();
          } catch (error) {
            // openErrorNotification(error, "Failed to load meetings");
          } finally {

          }
        };
    
        fetchData();
      }, []);

      const fetchAllCreatedProjects = async () => {
        const url_domain = apiDetails.domain;
        const version_path = apiDetails.version_path;
        const get_professiona_projects = "professional-projects";
        const fetch_url = `${url_domain}/${version_path}/${get_professiona_projects}`;
        const response = await fetch(fetch_url, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user_details.access_token}`
          }
        });
    
        // console.log("response from the get all assessment API call ");
        // console.log(response);
    
        if (!response.ok) {
          const errorData = await response.json();
          // openErrorNotification(errorData, "Failed to load meetings");
          // throw new Error(errorData);
          return
        }
    
        const data = await response.json();
        // console.log("response data from the get all meetings API call response.json() parsing function");
        // console.log(data);
    
        if (data && data.data && Array.isArray(data.data)) {
          console.log(data.data);
          setListOfCreatedProjects(data.data);
        }
    
      };

    const assessments = [
        { date: '12-Jun-2024', company: 'Seedson Ltd', country: 'Canada', industry: 'Health care' },
        { date: '12-Jun-2024', company: 'Brendy Ltd', country: 'Canada', industry: 'Telecoms' },
        { date: '12-Jun-2024', company: 'Karsen Ltd', country: 'Canada', industry: 'Finance' },
        { date: '12-Jun-2024', company: 'Meech Merch Ltd', country: 'Canada', industry: 'Oil & Gas' },
        { date: '12-Jun-2024', company: 'Meech Merch Ltd', country: 'Canada', industry: 'Oil & Gas' },
        { date: '12-Jun-2024', company: 'Meech Merch Ltd', country: 'Canada', industry: 'Oil & Gas' },
        { date: '12-Jun-2024', company: 'Meech Merch Ltd', country: 'Canada', industry: 'Oil & Gas' },
    ];

    const meetings = [
        { time: '8:00am | 12-Jun-2024', title: 'Create a marketing funnel...', company: 'Seedson Ltd', participants: 'Jane Thomas +2' },
        { time: '10:00am | 12-Jun-2024', title: 'Discuss Q3 sales strategy', company: 'Brendy Ltd', participants: 'John Doe +3' },
        { time: '1:00pm | 12-Jun-2024', title: 'Budget review meeting', company: 'Karsen Ltd', participants: 'Alice Green +1' },
    ];

    const projects = [
        { client: 'Seedson Ltd', title: 'Market Research', dueDate: '12-Jun-2024', price: '2300 CAD', participants: '4 people', level: '50%' },
        { client: 'Brendy Ltd', title: 'Product Launch Plan', dueDate: '15-Jun-2024', price: '5000 CAD', participants: '3 people', level: '30%' },
        { client: 'Karsen Ltd', title: 'Financial Analysis', dueDate: '20-Jun-2024', price: '4000 CAD', participants: '5 people', level: '70%' },
    ];

    return (
        <div className="dashboard">
            {currentView === 'dashboard' && (
                <>
                    <div className="card-container">
                        <Card
                            id="send"
                            imgUrl="/Images/exampaper.png"
                            title="Send assessment to clients."
                            description="Send an invitation to your prospective clients to take an assessment so you understand their gaps better."
                            buttonText="Send"
                            onClick={() => handleCardClick('send-assessment')}
                        />
                        <Card
                            id="book"
                            imgUrl="/Images/meetingcall.png"
                            title="Book meetings with clients or partners."
                            description="Go further to book meetings with clients or partners to plan deliverables."
                            buttonText="Book meetings"
                            onClick={() => handleCardClick('book')}
                        />
                        <Card
                            id="project"
                            imgUrl="/Images/projectMG.png"
                            title="Create, assign & manage projects"
                            description="Create a transparent system where everyone is notified for each completed milestone or task."
                            buttonText="Create"
                            onClick={() => handleCardClick('project')}
                        />
                    </div>

                    <div className="table-container">
                        <TabNavigation tabs={['New Assessments', 'Meetings', 'Projects']}>
                            {{
                                'New Assessments': <AssessmentTable assessments={assessments} />,
                                'Meetings': <MeetingsTable meetings={meetings} />,
                                'Projects': <ProjectsTable projects={listOfCreatedProjects} />,
                            }}
                        </TabNavigation>
                    </div>
                </>
            )}
            {currentView === 'send-assessment' && <InviteNavigation name={userName} user_details={user_details} />}
            {currentView === 'book' && <BookMeetings />}
            {currentView === 'project' && <ManageProjects user_details={user_details} />}
        </div>
    );
};

export default Dashboard;