// Task.js
import React, { useState } from 'react';
import calendarduedate from "../../assets/deadline.png";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const currentDate = new Date();
const dateFormat = 'YYYY-MM-DD';
// Minimum date set to today
const minDate = dayjs().format(dateFormat);


const Task = ({ taskData, taskIndex, milestoneIndex, handleTaskChange, onChangeDueDate }) => {
  const [taskDueDate, setTaskDueDate] = useState(dayjs(taskData.unformattedDate, dateFormat));

  const formatDate = (date) => {
    if (date === null || date === undefined) {
      const dateFormat = 'DD-MM-YYYY';
      const defaultDate = dayjs().format(dateFormat);
      return defaultDate;
    }

    let finaldate = "";
    const day = date.$D;
    if (day < 10) {
      finaldate = finaldate + "0" + day;
    } else {
      finaldate = finaldate + day;
    }

    const month = date.$M + 1;
    finaldate = finaldate + "-" + month;

    const year = date.$y;
    finaldate = finaldate + "-" + year;

    return (finaldate);
  };

  const handleDueDateChange = (date, dateString) => {
    setTaskDueDate(date);
    onChangeDueDate(formatDate(date), milestoneIndex, taskIndex, date);
  }

  return (
    // <div className="form-grouptask-row">
    //   <div className="form-group-half">
    //     <div className="task-input-group">
    //       <label>Task {taskIndex + 1}</label>
    //       <input
    //         type="text"
    //         name="task"
    //         className="task-input"
    //         placeholder="Enter task"
    //         onChange={(e) => handleTaskChange(e, milestoneIndex, taskIndex, 'task')}
    //       />
    //     </div>
    //     <div className="budget-input-group">
    //       <label>Budget</label>
    //       <input
    //         type="text"
    //         name="budget"
    //         className="budget-input"
    //         placeholder="Enter budget"
    //         onChange={(e) => handleTaskChange(e, milestoneIndex, taskIndex, 'budget')}
    //       />
    //     </div>
    //     <div className="due-date-input-group" style={{marginTop: "-0.75rem"}}>
    //       <label><img src={calendarduedate} alt='Assign' width={16} height={16} style={{ paddingRight: "5px" }} /> Due Date</label>
    //       <DatePicker
    //         needConfirm
    //         value={dayjs(projectFormDate, dateFormat)}
    //         minDate={dayjs(minDate, dateFormat)}
    //         size="medium"
    //       />
    //     </div>
    //   </div>
    // </div>
    <div className="form-grouptask-row">
      <div className="form-group-row">
        <div className="task-input-group">
          <label>Task {taskIndex + 1}</label>
          <input
            type="text"
            name="task"
            className="task-input"
            placeholder="Enter task"
            value={taskData.name}
            onChange={(e) => handleTaskChange(e, milestoneIndex, taskIndex, 'name')}
          />
        </div>
        <div className="budget-input-group">
          <label>Budget</label>
          <input
            type="text"
            name="budget"
            className="budget-input"
            placeholder="Enter budget"
            value={taskData.budget}
            onChange={(e) => handleTaskChange(e, milestoneIndex, taskIndex, 'budget')}
          />
        </div>
        <div className="due-date-input-group">
          <label>
            <img
              src={calendarduedate}
              alt="Assign"
              width={16}
              height={16}
              style={{ paddingRight: '5px' }}
            />{' '}
            Due Date
          </label>
          <div className="due-date-input">
            <DatePicker
              needConfirm
              value={dayjs(taskDueDate, dateFormat)}
              minDate={dayjs(minDate, dateFormat)}
              size="medium"
              onChange={handleDueDateChange}
              allowClear={false}
            />
          </div>
        </div>
      </div>
    </div>

  );
};

export default Task;
