// Milestone.js
import React, { useState } from 'react';
import Task from './Task';
import userprofile from "../../assets/user.png";
import calendarduedate from "../../assets/deadline.png";
import moneybag from "../../assets/money-bag.png";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const currentDate = new Date();
const dateFormat = 'YYYY-MM-DD';
// Minimum date set to today
const minDate = dayjs().format(dateFormat);

const Milestone = ({
    milestoneIndex,
    milestone,
    handleMilestoneChange,
    addTask,
    handleTaskChange,
    projectFormDate = "",
    listOfSubProfessionalsFromAPI = [],
    updateSubProfessionalForMilestone,
    onChangeDueDate
}) => {
    if (projectFormDate == "") {
        projectFormDate = minDate;
    }

    const [size, setSize] = useState(1); // Initial size set to 1
    const handleFocus = () => setSize(5); // Expand to show 5 options on focus
    const handleBlur = () => setSize(1); // Collapse back to 1 on blur
    const handleChange = (e) => {
        const selectedSubProfessional = e.target.value;
        updateSubProfessionalForMilestone(selectedSubProfessional, milestoneIndex);
    };

    return (
        <div className="milestone">
            <div className="form-group">
                <div className='labels-container-row' style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", alignContent: "center" }}>
                    <label>Milestone {milestoneIndex + 1}</label>
                    <div className='inline-milestone-labels' style={{ display: 'flex', alignItems: "center", alignContent: "center" }}>

                        {/* <div style={{ marginRight: "1rem" }}>
                            <span className="due-date" style={{ marginRight: "1rem", marginBottom: "10px" }}>
                                <img src={calendarduedate} alt='due date' width={16} height={16} style={{ paddingRight: "5px" }} /> Due Date
                            </span>
                            <DatePicker
                                needConfirm
                                value={dayjs(projectFormDate, dateFormat)}
                                minDate={dayjs(minDate, dateFormat)}
                                size="small"
                            />
                        </div> */}

                        <div style={{ marginRight: "1rem" }}>
                            <span className="assign-members" style={{ marginRight: "1rem", marginBottom: "10px" }}>
                                {/* <i className="icon-user"></i> Assign */}
                                <img src={userprofile} alt='Assign' width={16} height={16} style={{ paddingRight: "5px" }} /> Assign
                            </span>
                            <select
                                name="subsprofessional-canassign"
                                id="subsprofessional-canassign"
                                defaultValue=""
                                value={milestone.user_id}
                                onChange={handleChange}
                                style={{ paddingTop: "5px", paddingBottom: "5px", paddingleft: "8px", paddingRight: "8px", width: "15rem", borderRadius: "8px", maxHeight: "7rem", overflowY: "auto" }}
                            >
                                <option value="" disabled>
                                    Assign Professional
                                </option>
                                {listOfSubProfessionalsFromAPI.map((subprofessional, index) => (
                                    <option
                                        key={subprofessional.id}
                                        value={subprofessional.id}
                                        data-id={subprofessional.id}
                                        data-position={index}
                                        data-name={subprofessional.name}
                                    >
                                        {subprofessional.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ marginTop: "0.3rem" }}>
                            <span className="due-date" style={{ marginRight: "1rem", marginBottom: "10px" }}>
                                {/* <i className="icon-calendar"></i> Due date */}
                                <img src={moneybag} alt='milestone cost' width={16} height={16} style={{ paddingRight: "5px" }} /> Cost
                            </span>
                            <span>
                                <input
                                    type="number"
                                    name="milestonecost"
                                    placeholder="Total Cost"
                                    disabled
                                    style={{ paddingTop: "5px", paddingBottom: "8px", paddingleft: "8px", paddingRight: "8px", width: "6rem" }}
                                    value={milestone.cost}
                                />
                            </span>
                        </div>

                    </div>
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        name="milestone"
                        placeholder="Enter milestone"
                        value={milestone.name}
                        onChange={(e) => handleMilestoneChange(e, milestoneIndex)}
                    />
                </div>
            </div>

            {milestone.tasks.map((task, taskIndex) => (
                <Task
                    key={taskIndex}
                    taskData={task}
                    taskIndex={taskIndex}
                    milestoneIndex={milestoneIndex}
                    handleTaskChange={handleTaskChange}
                    onChangeDueDate={onChangeDueDate}
                />
            ))}

            <div className="taskbutton-row">
                <button type="button" className="add-task-button" onClick={() => addTask(milestoneIndex)}>Add new task</button>
            </div>

        </div>
    );
};

export default Milestone;
