import React, { useEffect, useState } from 'react';
import SidebarComponent from './SidebarComponent';
import UserToUserChatSectionComponent from './UserToUserChatSectionComponent';
import "../../CSS/MessagingPortal/UserChatInterface.css";

const UserChatInterface = ({ user_details }) => {

    // Disable scroll when this component is mounted
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        document.body.style.height = '100vh';    // Ensure height is full screen

        // Cleanup: Enable scroll again when the component unmounts
        return () => {
            document.body.style.overflow = 'auto'; // Restore scrolling
            document.body.style.height = 'auto';   // Reset height
        };
    }, []);

    const [selectedProfessional, setSelectedProfessional] = useState(null);

    const handleActiveChatChange = (professional_id) => {
        setSelectedProfessional(professional_id);
    };

    return (
        <div className="chat-container">
            <SidebarComponent onActiveChatChange={handleActiveChatChange} user_details={user_details} />

            {selectedProfessional === null ? (
                <div className='selectProfessionalToChat'>Select a Professional to start a conversation</div>
            ) : (
                <UserToUserChatSectionComponent selectedProfessional={selectedProfessional} user_details={user_details} />
            )}
        </div>
    );
};

export default UserChatInterface;