import React from 'react';

const ProjectsTable = ({ projects, onViewClick }) => {

    const extractNumericValue = (value) => {
        const numericPart = value.replace(/\D/g, ""); // "1234567890"
        const numericValue = Number(numericPart); // 1234567890 as a Number type
        return numericValue
    };

    const projectTotalPrice = (project) => {
        const milestones = project.milestones;
        const totalCost = milestones
            .flatMap(obj => obj.tasks)  // merge all tasks into one array
            .reduce((sum, task) => sum + Number(extractNumericValue(task.budget)), 0);
        return totalCost;
    };

    const projectParticipants = (project) => {
        const participantIds = new Set();
        const milestones = project.milestones;

        for (const milestone of milestones) {
            if (milestone.participant && milestone.participant.id) {
                participantIds.add(milestone.participant.id);
            }
        }

        const uniqueParticipantCount = participantIds.size;

        let finalValue; 
        if (uniqueParticipantCount === 1) {
            finalValue = "1 person";
        } else if (uniqueParticipantCount > 1) {
            finalValue = "" + uniqueParticipantCount + " people";
        }

        return finalValue;

    }

    return (
        <table className="assessment-table">
            <thead>
                <tr className="table-header">
                    <th>Clients Name</th>
                    <th>Project Title</th>
                    <th>Due Date</th>
                    <th>Price</th>
                    <th>Participants</th>
                    <th>Level</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {projects.map((project, index) => (
                    <tr key={project.id} className="each-cell">
                        <td className="company-cell">{project.client}</td>
                        <td>{project.name}</td>
                        <td className="due-cell">{project.end_date}</td>
                        <td className="price-cell">CAD {projectTotalPrice(project)}</td>
                        <td>{projectParticipants(project)}</td>
                        <td>{project.completion_level}%</td>
                        <td>
                            <button onClick={() => onViewClick(project)}>View</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProjectsTable;
