import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';

const { Option } = Select;

const selectStyle = {
  height: '70px', // Match height with Input fields
  width: '100%',  // Full width
}

const SignupForm = ({ formItems, onFinish, buttonText, sendDataToParent, loadSearchParams = () => { }, emailForSubProfessionalRegistrationProp = "" }) => {
  const [visiblePassword, setVisiblePassword] = useState({});
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasMinLength: false,
    hasSpecialCharacter: false,
  });
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const myRef = useRef(null);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const email = searchParams.get('email');
        const id = searchParams.get('id');
        form.setFieldsValue({ email: email });

        if (myRef.current && myRef.current.input) {
          myRef.current.input.value = email;
        }
        
        loadSearchParams(email, id);
      } catch (error) {
        console.error('There was an error fetching the industries data!', error);
      }
    };
  
    fetchIndustries();
  }, [form]);
  

  const togglePasswordVisibility = (name) => {
    setVisiblePassword((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const validatePassword = (password) => {
    setPasswordCriteria({
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasMinLength: password.length >= 8,
      hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };





  return (
    <div className='signup-forms'>
      <Form form={form} onFinish={onFinish} size='large'>
        {formItems.map((item, index) => (
          <Form.Item
            layout='vertical'
          key={index}
            label={item.label}
            name={item.name}
            rules={item.rules}
            validateTrigger={['onBlur', 'onSubmit']}
            className={item.value === 'pwdcheck' ? 'password-item' : ''}
          >
            <div style={{ position: 'relative' }}>
              <>
                <Input
                  id={item.name}
                  type={
                    item.name === 'password' || item.name === 'confirmPassword'
                      ? visiblePassword[item.name]
                        ? 'text'
                        : 'password'
                      : 'text'
                  }
                  size='large'
                  onChange={(e) => {
                    if (item.name === 'password') {
                      validatePassword(e.target.value);
                    }
                  }}
                  addonAfter={
                    (item.name === 'password' || item.name === 'confirmPassword') && (
                      <span onClick={() => togglePasswordVisibility(item.name)} style={{ cursor: 'pointer' }}>
                        {visiblePassword[item.name] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </span>
                    )
                  }
                  disabled={item.name === 'email'} // Disable the email field
                  ref={item.name === "email" ? myRef: null}
                />

                {item.value === 'pwdcheck' && (
                  <div className="password-criteria">
                    <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                      <li style={{ color: passwordCriteria.hasMinLength ? 'green' : 'red' }}>
                        {passwordCriteria.hasMinLength ? '✔' : '✖'} Minimum 8 characters
                      </li>
                      <li style={{ color: passwordCriteria.hasLowercase ? 'green' : 'red' }}>
                        {passwordCriteria.hasLowercase ? '✔' : '✖'} At least one lowercase letter
                      </li>
                      <li style={{ color: passwordCriteria.hasUppercase ? 'green' : 'red' }}>
                        {passwordCriteria.hasUppercase ? '✔' : '✖'} At least one uppercase letter
                      </li>
                      <li style={{ color: passwordCriteria.hasNumber ? 'green' : 'red' }}>
                        {passwordCriteria.hasNumber ? '✔' : '✖'} At least one number
                      </li>
                      <li style={{ color: passwordCriteria.hasSpecialCharacter ? 'green' : 'red' }}>
                        {passwordCriteria.hasSpecialCharacter ? '✔' : '✖'} At least one special character
                      </li>
                    </ul>
                  </div>
                )}
              </>

              {item.value === 'pwd1' && (
                <div style={{ textAlign: 'left', marginTop: '5px' }}>
                  <a href="/forgot-password" style={{ color: 'black', fontSize: '16px' }}>
                    Forgot Password?
                  </a>
                </div>
              )}
            </div>
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignupForm;





