import React, { useState } from "react";
import InviteBusinesses from "./InviteBusinesses";
import BusinessList from "./BusinessList";
import apiDetails from "../../assets/apiDetails/apiDetails.json";
import { Flex, Spin, notification } from "antd";

const InviteNavigation = ({ name, user_details }) => {
  const [currentPage, setCurrentPage] = useState("invite"); // "invite" or "list"
  const [startingTab, setStartingTab] = useState("completed"); // Set default to completed
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();


  const handleSendInvitation = (businessesemails) => {
    async function executeApiCalls() {
      await Promise.all([
        inviteBusinessesForAssessment(businessesemails)
      ]);
      setLoading(false);

    }
    setLoading(true);
    executeApiCalls();
  };

  const inviteBusinessesForAssessment = async (businessesemails) => {
    try {
      const url_domain = apiDetails.domain;
      const version_path = apiDetails.version_path;
      const invite_business_to_take_assessment = "assessment/invite-business";
      const fetch_url = `${url_domain}/${version_path}/${invite_business_to_take_assessment}`;
      const apiBody = {
        "emails": businessesemails
      };

      const response = await fetch(fetch_url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_details.access_token}`
        },
        body: JSON.stringify(apiBody)
      });

      // console.log("response from the send message between user and professional API call ");
      // console.log(response);

      // Check if the response status is not acceptable
      if (!response.ok) {
        const error_data = await response.json();
        // console.log("error_data", error_data);
        openErrorNotification(error_data);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log("response from the inviteBusinessesForAssessment API call response.json() parsing function");
      // console.log(data);

      if (data) {
        openSuccessNotification(data);
        setCurrentPage("list");
        setStartingTab("completed"); // Default tab is completed on send
      }

    } catch (error) {
      // setUserRecentChats(userRecentChatListTestingData);
      // console.error('Error fetching data from API 3 - get all remediations API call', error);
    }
  };

  const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
    // console.loglog("notificationParameters = ", notificationParameters);
    api["error"]({
      message: errorTitle,
      description: notificationParameters.message,
      showProgress: true,
      pauseOnHover: true,
      duration: 0,
    });
  };

  const openSuccessNotification = (notificationParameters, successTitle = "Success") => {
    // console.loglog("notificationParameters = ", notificationParameters);
    api["success"]({
      message: successTitle,
      description: notificationParameters.message,
      showProgress: true,
      pauseOnHover: true,
      duration: 30,
    });
  };

  const handleAddEmail = () => {
    setCurrentPage("list");
    setStartingTab("incomplete"); // Land on incomplete tab after adding another email
  };

  const handleBackClick = () => {
    setCurrentPage("invite");
  };

  const handleInviteClick = () => {
    setCurrentPage("invite");
  }; 

  return (
    <div>
      {contextHolder}
      {(loading) ? (
        <Flex align="center" gap="middle" className="loaderSpinner">
          <Spin size="large" className='spinner-container' />
        </Flex>
      ) : (
        <>
          {currentPage === "invite" && <InviteBusinesses onSendInvitation={handleSendInvitation} name={name} />}
          {currentPage === "list" && <BusinessList onBackClick={handleBackClick} startingTab={startingTab} onInviteClick={handleInviteClick} name={name} />}
        </>
      )}

    </div>
  );
};

export default InviteNavigation;
