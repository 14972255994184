// import React, { useState , useEffect} from 'react';
// import { Form, Input, Button, Select } from 'antd';
// import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

// const { Option } = Select;

// const selectStyle = {
//   height: '70px', // Match height with Input fields
//   width: '100%',  // Full width
// }

// const SignupForm = ({ formItems, onFinish, buttonText, sendDataToParent }) => {
//   const [visiblePassword, setVisiblePassword] = useState({});
//   const [industries, setIndustries] = useState([]);
//  const [industry, setIndustry] = useState("");

//   useEffect(() => {
//     const fetchIndustries = async () => {
//       try {
//         const response = await fetch('https://api.anvillist.com/api/v1/industries');
//         const data = await response.json();
//         // const formattedOptions = data.map(item => ({
//         //   value: item.toLowerCase().replace(/ /g, '-'), // Convert to a value-friendly format (e.g., "Aerospace" -> "aerospace")
//         //   label: item // Use the string as the label
//         // }));
//         setIndustries(data);
//       } catch (error) {
//         console.error('There was an error fetching the industries data!', error);
//       }
//     };

//     fetchIndustries();
//   }, []);


//   const handleChange = (e) => {
//     console.log("event ", e)
//     setIndustry(e);
//     sendDataToParent(e); // Send data to parent
//   };

//   const togglePasswordVisibility = (name) => {
//     setVisiblePassword((prevState) => ({
//       ...prevState,
//       [name]: !prevState[name],
//     }));
//   };

//   return (
//     <div className='signup-forms'>
//       <Form onFinish={onFinish} 
//       // initialValues={{ industry: "Betting" }} 
//       size='large'>
//         {formItems.map((item, index) => (
//           <Form.Item
//             layout='vertical'
//             key={index}
//             label={item.label}
//             name={item.name}
//             value={item.value}
//             rules={item.rules}
//             valuePropName="value"
//             validateTrigger={['onBlur', 'onSubmit']}
//           >
//             <div style={{ position: 'relative' }}>
//               {item.name === 'industry' ? (
//                 <Select
//                   size='large'
//                   style={selectStyle} 
//                   placeholder="Select an industry"
//                   onChange={(value) => {
//                     // Update the form value when an industry is selected
//                    console.log(value)
//                    handleChange(value)
//                   }} 
//                 >
//                   {industries.map((industry) => (
//                     <Option key={industry} value={industry}>
//                       {industry}
//                     </Option>
//                   ))}
//                 </Select>
//               ) : (
//                 <Input
//                   type={
//                     item.name === 'password' || item.name === 'confirmPassword'
//                       ? visiblePassword[item.name]
//                         ? 'text'
//                         : 'password'
//                       : 'text'
//                   }
//                   size='large'

//                   addonAfter={
//                     (item.name === 'password' || item.name === 'confirmPassword') && (
//                       <span onClick={() => togglePasswordVisibility(item.name)} style={{ cursor: 'pointer' }}>
//                         {visiblePassword[item.name] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
//                       </span>
//                     )
//                   }
//                 />
//               )}

//               {item.value === 'pwd1' && (
//                 <div style={{ textAlign: 'left', marginTop: '5px' }}>
//                   <a href="/forgot-password" style={{ color: 'black', fontSize: '16px' }}>
//                     Forgot Password?
//                   </a>
//                 </div>
//               )}
//             </div>
//           </Form.Item>
//         ))}
//         <Form.Item>
//           <Button type="primary" htmlType="submit">
//             {buttonText}
//           </Button>
//         </Form.Item>
//       </Form>
//     </div>
//   );
// };

// export default SignupForm;




import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const { Option } = Select;

const selectStyle = {
  height: '70px', // Match height with Input fields
  width: '100%',  // Full width
}

const SignupForm = ({ formItems, onFinish, buttonText, sendDataToParent}) => {
  const [visiblePassword, setVisiblePassword] = useState({});
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasMinLength: false,
    hasSpecialCharacter: false,
  });

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await fetch('https://api.anvillist.com/api/v1/industries');
        const data = await response.json();
        setIndustries(data);
      } catch (error) {
        console.error('There was an error fetching the industries data!', error);
      }
    };

    fetchIndustries();
  }, []);

  const handleChange = (e) => {
    setIndustry(e);
    sendDataToParent(e); // Send data to parent
  };

  const togglePasswordVisibility = (name) => {
    setVisiblePassword((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const validatePassword = (password) => {
    setPasswordCriteria({
      hasLowercase: /[a-z]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /\d/.test(password),
      hasMinLength: password.length >= 8,
      hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  return (
    <div className='signup-forms'>
      <Form onFinish={onFinish} size='large'>
        {formItems.map((item, index) => (
          <Form.Item
            layout='vertical'
            key={index}
            label={item.label}
            name={item.name}
            value={item.value}
            rules={item.rules}
            valuePropName="value"
            validateTrigger={['onBlur', 'onSubmit']}
            className={item.value === 'pwdcheck' ? 'password-item' : ''}
          >
            <div style={{ position: 'relative' }}>
              {item.name === 'industry' ? (
                <Select
                  size='large'
                  style={selectStyle}
                  placeholder="Select an industry"
                  onChange={(value) => {
                    handleChange(value);
                  }}
                >
                  {industries.map((industry) => (
                    <Option key={industry} value={industry}>
                      {industry}
                    </Option>
                  ))}
                </Select>
              ) : (
                <>
                  <Input
                    type={
                      item.name === 'password' || item.name === 'confirmPassword'
                        ? visiblePassword[item.name]
                          ? 'text'
                          : 'password'
                        : 'text'
                    }
                    size='large'
                    onChange={(e) => {
                      if (item.name === 'password') {
                        validatePassword(e.target.value);
                      }
                    }}
                    addonAfter={
                      (item.name === 'password' || item.name === 'confirmPassword') && (
                        <span onClick={() => togglePasswordVisibility(item.name)} style={{ cursor: 'pointer' }}>
                          {visiblePassword[item.name] ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </span>
                      )
                    }
                  />

                  {item.value === 'pwdcheck' && (
                    <div className="password-criteria">
                      <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                        <li style={{ color: passwordCriteria.hasMinLength ? 'green' : 'red' }}>
                          {passwordCriteria.hasMinLength ? '✔' : '✖'} Minimum 8 characters
                        </li>
                        <li style={{ color: passwordCriteria.hasLowercase ? 'green' : 'red' }}>
                          {passwordCriteria.hasLowercase ? '✔' : '✖'} At least one lowercase letter
                        </li>
                        <li style={{ color: passwordCriteria.hasUppercase ? 'green' : 'red' }}>
                          {passwordCriteria.hasUppercase ? '✔' : '✖'} At least one uppercase letter
                        </li>
                        <li style={{ color: passwordCriteria.hasNumber ? 'green' : 'red' }}>
                          {passwordCriteria.hasNumber ? '✔' : '✖'} At least one number
                        </li>
                        <li style={{ color: passwordCriteria.hasSpecialCharacter ? 'green' : 'red' }}>
                          {passwordCriteria.hasSpecialCharacter ? '✔' : '✖'} At least one special character
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}

              {item.value === 'pwd1' && (
                <div style={{ textAlign: 'left', marginTop: '5px' }}>
                  <a href="/forgot-password" style={{ color: 'black', fontSize: '16px' }}>
                    Forgot Password?
                  </a>
                </div>
              )}
            </div>
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignupForm;





